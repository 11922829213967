/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    h3: "h3",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Sioux Falls, SD, April 07, 2022:"), " Prismatic, whose ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platform"), " (", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), ") enables SaaS companies to quickly deliver product integrations, announced today that its platform is now available on AWS GovCloud (US)."), "\n", React.createElement(_components.p, null, "The expansion into GovCloud means SaaS companies serving public sector organizations can now leverage the power of embedded iPaaS to deliver ", React.createElement(_components.a, {
    href: "/resources/build-vs-buy-adding-native-integrations-to-your-product/"
  }, "native integrations"), " while fully supporting their customers' stringent data handling requirements."), "\n", React.createElement(_components.p, null, "Prismatic encompasses an intuitive integration designer, ", React.createElement(_components.a, {
    href: "/resources/integration-marketplace-for-b2b-saas/"
  }, "embedded integration marketplace"), ", integration deployment and support, and purpose-built cloud infrastructure. It is the embedded integration platform of choice for SaaS companies that need to build productized native integrations to their customers' other apps."), "\n", React.createElement(_components.p, null, "This move to enable the public sector by expanding to AWS GovCloud is another example of Prismatic's continuing investment in enterprise-level security measures, most recently highlighted by its ", React.createElement(_components.a, {
    href: "../soc-2-type-1-audit/"
  }, "SOC 2 certification"), "."), "\n", React.createElement(_components.p, null, "\"We are very excited to make the improved security and reliability provided by AWS GovCloud available to our customers who serve the public sector,\" said Justin Hipple, Prismatic CTO and Cofounder."), "\n", React.createElement(_components.p, null, "AWS GovCloud is built to support state and federal agencies and public sector organizations as they meet specific regulatory and compliance requirements for their sensitive data in the cloud. These requirements include the FBI's Criminal Justice Information Services (CJIS), the Department of Defense's Security Requirements Guide (SRG), the International Traffic in Arms Regulations (ITAR), the Export Administration Regulations (EAR), the Federal Risk and Authorization Management Program (FedRAMP), and the Health Insurance Portability and Accountability Act (HIPAA)."), "\n", React.createElement(_components.p, null, "\"One reason SaaS teams from a wide variety of industries choose Prismatic is our ability to handle even their most complex and industry-specific requirements,\" said Michael Zuercher, Prismatic CEO and Cofounder. \"We've seen substantial interest in Prismatic from SaaS teams in the public sector space, where security and compliance are critical. Deploying an embedded integration platform in GovCloud requires a significant investment in platform infrastructure, and we're proud to be the first embedded iPaaS provider to make that investment.\""), "\n", React.createElement(_components.h3, {
    id: "contact",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact",
    "aria-label": "contact permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact"), "\n", React.createElement("p", null, React.createElement(_components.p, null, React.createElement("strong", null, "Prismatic"), " ", React.createElement("br"), "\nBeth Harwood ", React.createElement("br"), "\nVP Marketing & Developer Relations, Cofounder ", React.createElement("br"), "\n888-305-5453 ", React.createElement("br"), "\n", React.createElement(_components.a, {
    href: "mailto:press@prismatic.io",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "press@prismatic.io"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
